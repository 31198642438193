@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@700&display=swap);
/* to change color from white is in getstatus */ 

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  background-color:#111;
  padding: 0;
  /*  display: flex; idk if this works ---- This is why i couldnt get content to align */ 
  color: white;
  background-image: url(/static/media/stars.86f7d1b4.jpeg);
  background-repeat: repeat;
  max-width: 600px; 
  margin: 0 auto; 
}
p { 
  margin: 0px;
  padding: 0px;
} 

#Data {
  text-align: center;
}
#Dev { 
  
}
#DevRoadmap {
  text-align: center;
}
#diviroadmap{
  padding-left: 10px;
}
#roadmap_content {
  padding-left: 20px;
}
#title{
  background-color: black;
}


#title2 {
  font-size: 2em;
}
.Minter {
  background-color:black;
}
#introduction {
  padding: 20px;
  background-color: #999;
  color: black;
}
#rarity { /* V A R I A T I O N S */ 
  border: 10px solid #999;
  background-color: #111;
}
#roadmap {
  border: 2px solid #999;
}
#community { /* COMMUNITY */ 
    font-family: 'Fira Code', cursive;

}
#disclaimer { /* disclaimer */ 
  border: 5px solid #999;
  background-color: #111;
}
#border {
  border: 5px solid #999;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#link { 
  color:#999;
  
}
.hovfont {
  font-family: 'Cinzel Decorative', cursive ;
}
#statbutton:hover {
  background-color: #999;
  color:#111;
  border: 6px solid #111;
}
#statbutton {
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #999;
  border: 4px solid #999;
  padding: 8px;
  font-size: 18px;
  margin: 1px 1px;
  cursor: pointer;
  transition-duration: 0.8s;
}
#statbutton:hover {
  background-color: #999;
  color:#111;
  border: 6px solid #111;
}
#button {
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #999;
  border: 4px solid #999;
  padding: 18px;
  font-size: 20px;
  margin: 4px 4px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;

}
#button:hover {
  background-color: #999;
  color:#111;
}
#ticketandminter {
   -webkit-flex-direction:row;
           flex-direction:row;
   width: 100%; 
   height:400px; 
}
#buttonspecial { 
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #FFF;
  border: 2px solid #fff;
  padding: 18px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;
}
#buttonspecial:hover {
  background-color: #FFF;
  color:#111;
}
#buttonticketandcount { 
  width: 382px; 
  height: 160px; 
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #FFF;
  border: 2px solid #fff;
  padding: 1px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;
}
#buttonticketandcount:hover {
  background-color: #FFF;
  color:#111;
}
#buttonmintandredeem {
  width: 382px;
}
#cubequantityinputbox {
  
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #FFF;
  border: 2px solid #fff;
  padding: 18px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;
  width: 4em;  
} 
h1 { 
  font-size: 40px;
}

#walletButton {
  text-align: right;
  float:right;
  margin-bottom: 50px;
  line-height: 16px;
  background-color: black;
  color:#999;
  padding: 18px;

}
#supermint {
}
#ladydescription{
  text-align: center;
  content-align: center;
}
/*
#walletButton:focus {
  outline:none;
  border: none;
}

#walletButton:hover {
  outline:none;
  border :none;
} */

#mintButton {
  margin-top: 40px;
  background-color: black;
  color:white;
}
#nft {
  width: 400px;
  height: 400px;
  text-align: center;
}
#nftmain {
  width: 308px;
  height: 400px;
  text-align: center;
  border: none;
}

#provenance { /* PROVENANCE */ 
  border: 5px solid #999;
  text-align: center;
}
#provenancelist {
  text-align: center;

  list-style-type: none;
  margin: 0;
  padding: 0;
}
#provenancelist {
  margin: 0;
  padding: 0;
  
}
#provenancelisthead {
  font-size: 1.4em
  
}
#masterdirs {  
  font-family: 'Fira Code', cursive;

  font-size: 0.75em;
}
#ipfs_desc {
  font-size: 1.8em;
}
#ipfs_desc_small {
  font-size: 1.7em;
}
.hash {
  font-size: 0.5em;
  color: #20C20E;
  font-weight: 800;
  text-decoration: none;
  font-family: 'Press Start 2P', cursive;

}
.hash:hover {
  font-size: 0.55em;
  color: #20C20E;
  font-weight: 800;
  text-decoration: underline;
}
.hash_alt {
  font-size: 0.5em;
  color: #20C20E;
  font-weight: 800;
  text-decoration: none;
  font-family: 'Press Start 2P', cursive;
}

.hash_alt:hover {
  color: #20C20E;
  font-weight: 800;
  text-decoration: underline;
  font-size: 0.55em;

}
.tokenid {
  font-family: 'Fira Code', cursive;

 color: white;
 text-decoration: none;

}
.tokenid {
  font-family: 'Fira Code', cursive;

 color: white;
 text-decoration: none;

}
.tokenidname {
 
 color: white;
 text-decoration: none;

}
.tokenidname {
 
 color: white;
 text-decoration: none;

}
.META {
  color: #20C20E;
  font-size: 0.6em;
  text-decoration: none;

}
.META:HOVER { 
  text-decoration: underline;
  font-weight: 800;

}
#fira { 
  font-family: 'Fira Code', cursive;
}
#provenancelisthead {
  font-family: 'Fira Code', cursive;
  font-size: 1.4em;

}

#roadmap {
  font-family: 'Fira Code', cursive;
  font-size: 1.4em;

}

#hacker2 {
  color: #20C20E; 

}

#hacker3 {
  color: greenyellow; 

}
#info {
  border: 2px solid #fff;
}

/* navbar */ 
.navlink {
  
  font-family: "Press Start 2P",cursive;
  background-color: #999;
  color: #111;
  border: 4px solid #111;
  padding: 18px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: .8s;
}
#roadmap {
  border: 2px solid #999;
}
#manifestoroadmap {
  border: 2px solid #999;
  font-family: 'Fira Code', cursive;
  font-size: 1.4em;
  width: 670px;

}
#manifestoheader { 
  font-size: 1.3em;
  padding-left: 10px;
  
}
#manifestocontent {
  font-size: .85em;
  padding-left: 15px;
  width: 650px;
}


/* navbar, i accidentally placed this outside of mobile settings, but i like this better  */ 
.navlink {
  
  font-family: "Press Start 2P",cursive;
  background-color: #999;
  color: #111;
  border: 4px solid #111;
  padding: 18px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: .8s;
  margin-bottom: 20px;
  
}
#datanavbar {
  text-align: left;
}
#logo { 
  width: 600px;
}
#logocubes { 
  width: 600px;
}
#tutorial { 
  border: 20px solid #999;
  width: 400px;
}
#quantitydiv{

 }

#quantityhead{ 
  padding: 3px;
}
#sitemapdiv {
  text-align: left;
  font-size: 1em;
  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.75), black 120%
  );
}
#terminaldiv {
  text-align: left;
  font-size: 1em;

  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.75), black 120%
  );
  margin: 0;
  padding: 1rem;
  color: white;
  text-shadow: 0 0 8px #C8C8C8;
  &::after {
    content: "";
    background: repeating-linear-gradient(
      0deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 1px,
      transparent 1px,
      transparent 2px
    );
    pointer-events: none;
  }
}

#terminaldiv_idlist {
  text-align: left;
  font-size: 1em;

  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.75), black 120%
  );
  margin: 0;
  padding: 1rem;
  color: white;
  text-shadow: 0 0 5px #C8C8C8;
  
  
  {/* padding-inline-start: 0px; */} 

  &::after {
    content: "";
    background: repeating-linear-gradient(
      0deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 1px,
      transparent 1px,
      transparent 2px
    );
    pointer-events: none;
  }
}
{/* 
#terminaldiv::after {
  content: "";
  background: repeating-linear-gradient(
    0deg,
    rgba(black, 0.15),
    rgba(black, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );
}
*/}
#paddingdiv{ 
  padding: 5px;
}
#launchdate{
  font-size: 2em;
}

#border2{ 
  border: 1px solid grey;
}
.MM-link {
  color:white; 
}
.App {
  text-align: center;
}

.App-header {
  background-color: #111;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: white;
}

/* phones */ 
@media screen and (max-width: 800px) {

    body {
        margin: 0;
        font-family: 'Press Start 2P', cursive;
        background-color:#111;
        padding: 0;
        /*  display: flex; idk if this works ---- This is why i couldnt get content to align */ 
        color: white;
        background-image: url(/static/media/stars.86f7d1b4.jpeg);
        background-repeat: repeat;
        max-width: 300px; /* just changed !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
        width: 300px;
        margin: 0 auto; 
      }
      p { 
        margin: 0px;
        padding: 0px;
      } 
      #title{
        background-color: black;
      }
      .Minter {
        background-color:black;
      }
      #introduction {
        background-color: #999;
        color: black;
      }
      #rarity { /* V A R I A T I O N S */ 
        border: 10px solid #999;
        background-color: #111;
      }
      #provenance { /* PROVENANCE */ 
        font-size: .6em;
      }
      #roadmap {
        border: 2px solid #999;
      }
      #community { /* COMMUNITY */ 
          font-family: 'Fira Code', cursive;
      
      }
      #disclaimer { /* disclaimer */ 
        border: 5px solid #999;
        background-color: #111;
        font-size: .6em;

      }
      #border {
        border: 5px solid #999;
      }
      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
      }
      
      #link { 
        color:#999;
        
      }
      .hovfont {
        font-family: 'Cinzel Decorative', cursive ;
      }
      #statbutton:hover {
        background-color: #999;
        color:#111;
        border: 6px solid #111;
      }
      #statbutton {
        font-family: 'Press Start 2P', cursive;
        background-color: #111;
        color: #999;
        border: 4px solid #999;
        padding: 8px;
        font-size: 18px;
        margin: 1px 1px;
        cursor: pointer;
        transition-duration: 0.8s;
      }
      #statbutton:hover {
        background-color: #999;
        color:#111;
        border: 6px solid #111;
      }
      #button {
        font-family: 'Press Start 2P', cursive;
        background-color: #111;
        color: #999;
        border: 4px solid #999;
        padding: 18px;
        font-size: 20px;
        margin: 4px 4px;
        cursor: pointer;
        border-radius: 12px;
        transition-duration: 0.8s;
      
      }
      #button:hover {
        background-color: #999;
        color:#111;
      }
      #buttonspecial { 
        font-family: 'Press Start 2P', cursive;
        background-color: #111;
        color: #FFF;
        border: 2px solid #fff;
        padding: 18px;
        font-size: 20px;
        cursor: pointer;
        border-radius: 12px;
        transition-duration: 0.8s;
      }
      #buttonspecial:hover {
        background-color: #FFF;
        color:#111;
      }
      h1 { 
        font-size: 30px;
      }
      
      #walletButton {
        text-align: center;
        float:center;
        line-height: 16px;
        background-color: black;
        color:#999;
        width: 300px;
      }
      #supermint {
      }
      #ladydescription{
        text-align: center;
        content-align: center;
      }
      /*
      #walletButton:focus {
        outline:none;
        border: none;
      }
      
      #walletButton:hover {
        outline:none;
        border :none;
      } */
      
      #mintButton {
        margin-top: 40px;
        background-color: black;
        color:white;
      }
      #nft {
        width: 300px;
        height: 300px;
        text-align: center;
      }
      #mobilenft {
      }
      #nftmain {
        display: -webkit-flex;
        display: flex;
        padding: 0px;
        margin: 0px;
        width: 350px;
        height: 350px;
        text-align: left;
        border: none;
        overflow: hidden;

      }
      #provenance { /* PROVENANCE */ 
        border: 5px solid #999;
        text-align: center;
      }
      #provenancelist {
        text-align: center;
      
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      #provenancelist {
        margin: 0;
        padding: 0;
        
      }
      #provenancelisthead {
        font-size: 1.4em
        
      }
      #masterdirs {  
        font-family: 'Fira Code', cursive;
      
        font-size: 1em;
      }
      #ipfs_desc {
        font-size: 2em;
      }
      #ipfs_desc_small {
        font-size: 2em;
      }
      .hash {
        font-size: 0.5em;
        color: #20C20E;
        font-weight: 800;
        text-decoration: none;
        font-family: 'Press Start 2P', cursive;
      
      }
      .hash:hover {
        font-size: 0.55em;
        color: #20C20E;
        font-weight: 800;
        text-decoration: underline;
      }
      .hash_alt {
        font-size: 0.5em;
        color: #20C20E;
        font-weight: 800;
        text-decoration: none;
        font-family: 'Press Start 2P', cursive;
      }
      
      .hash_alt:hover {
        color: #20C20E;
        font-weight: 800;
        text-decoration: underline;
        font-size: 0.55em;
      
      }
      .tokenid {
        font-family: 'Fira Code', cursive;
      
       color: white;
       text-decoration: none;
      
      }
      .tokenid {
        font-family: 'Fira Code', cursive;
      
       color: white;
       text-decoration: none;
      
      }
      .META {
        color: #20C20E;
        font-size: 0.6em;
        text-decoration: none;
      
      }
      .META:HOVER { 
        text-decoration: underline;
        font-weight: 800;
      
      }
      
      #fira { 
        font-family: 'Fira Code', cursive;
      }
      #provenancelisthead {
        font-family: 'Fira Code', cursive;
        font-size: 2em;
      
      }
      
      #roadmap {
        font-family: 'Fira Code', cursive;
        font-size: 1em;
      
      }
      
      #hacker2 {
        color: #20C20E; 
      
      }

      #manifestoroadmap {
        font-family: 'Fira Code', cursive;
        font-size: 1em;
        width: 320px;
      
      }
      #manifestoheader { 
        font-size: 1.3em;        
      }
      #manifestocontent {
        font-size: .85em;
        width: 270px;
      }
      #linksdiv {
        width: 1000px;
        max-width: 2000px;
      }
      .navlink {
  
        font-family: "Press Start 2P",cursive;
        background-color: #999;
        color: #111;
        border: 4px solid #111;
        padding: 10px;
        font-size: 10px;
        cursor: pointer;
        border-radius: 12px;
        transition-duration: .8s;
        margin-bottom: 20px;
        inherits: none; 
      }
      #provbutton {
      }
      #datanavbar{ 
        text-align: left;
      }
      #minternavbar{ 
        text-align: left;
      }
      #logomystery { 
        width: 250px;
      }
      #buttonmintandredeem{
        width: 200px;
      }
      #tutorial{
        width: 280px;
        border: 4px solid grey;
      }
      #logocubes{
        width: 300px;
        text-align: left;

      }
      #artworkimg{
        width:300px;
      }
      #launchdate{
        font-size: 1.7em;
      }
      /*Welcome to Club Main Terminal section */ 
      #terminaldiv{
        font-size: .94em;
        
      }
      #hacker3{ 
        line-height: 45px;
      }
      #provenancelist{
        font-size: .91em !important;
      }
}



/* id system mobile is in idsystem.css */ 


.IDSystem{
}
#Badge {
    display: -webkit-flex;
    display: flex;
    font-size: .9em;
    width: 350px;
}
#Badgeinput {
    display: -webkit-flex;
    display: flex;
    background-color: black;
    color: #20C20E;
}
#flex_idsystem { 
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    width: 1000px;
    height: 500px;
    background-color: black;
  
}
#idcube { 
    height: 216px;
    width: 216px;
    -webkit-align-content: left;
            align-content: left;
    text-align: left; 
    border: none;
    box-sizing: border-box;
    
}
#stats {
    padding-top: 20px;

    text-align: left; 
}
#cube_text {
    max-width: 400px;
    max-height: 100px;
    overflow: hidden !important;    
    font-size: .8em;
    color: #20C20E;
    font-family: 'Fira Code', cursive;

}
#artwork {
    overflow: hidden !important;
}
#artworkimg {
    overflow: hidden !important;
    width: 600px;
    height: 300px;
}
#hacker {
    color: #20C20E; 
    font-family: 'Fira Code', cursive;

}
#hacker-link {
    color: #20C20E;  
        font-family: 'Fira Code', cursive;

}
#terminaldiv_IDSystem {
    text-align: left;

  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.75), black 120%
  );
  margin: 0;
  padding: .7rem;
  color: white;

  
  
  {/* padding-inline-start: 0px; */} 

  &::after {
    content: "";
    background: repeating-linear-gradient(
      0deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 1px,
      transparent 1px,
      transparent 2px
    );
    pointer-events: none;
  }

}
#black {
    color: black; 
}
/* phones */ 
@media screen and (max-width: 800px) {
    .IDSystem{
    }
    #Badge {
        display: -webkit-flex;
        display: flex;
    }
    #Badgeinput {
        display: -webkit-flex;
        display: flex;
        background-color: black;
        color: #20C20E;
    }
    #flex_idsystem { 
        display:block;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
        width: 300px;
        height: 500px;
        background-color: black;
        border: 10px white;
    }
    #idcube { 
        height: 216px;
        width: 216px;
        -webkit-align-content: left;
                align-content: left;
        text-align: center; 
        border: none;
        box-sizing: border-box;
        
    }
    #stats {
        text-align: left; 
    }
    #cube_text {
        max-width: 300px;
        max-height: 150px;
        overflow: hidden !important;    
        font-size: .8em;
        color: #20C20E;
        font-family: 'Fira Code', cursive;
    
    }
    #artworklink {
       {/* font-size: .8em; */} 
    }
    #artwork {
    }
    #artworkimg {
        width: 600px;
        height: 200px;
        margin: 10px;
    }
    #hacker {
        color: #20C20E; 
        font-family: 'Fira Code', cursive;
    
    }
    #hacker-link {
        color: #20C20E;  
            font-family: 'Fira Code', cursive;
    
    }
    #terminaldiv_IDSystem { 

    }
}
