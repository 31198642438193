.IDSystem{
}
#Badge {
    display: flex;
    font-size: .9em;
    width: 350px;
}
#Badgeinput {
    display: flex;
    background-color: black;
    color: #20C20E;
}
#flex_idsystem { 
    display: flex;
    flex-wrap: nowrap;
    width: 1000px;
    height: 500px;
    background-color: black;
  
}
#idcube { 
    height: 216px;
    width: 216px;
    align-content: left;
    text-align: left; 
    border: none;
    box-sizing: border-box;
    
}
#stats {
    padding-top: 20px;

    text-align: left; 
}
#cube_text {
    max-width: 400px;
    max-height: 100px;
    overflow: hidden !important;    
    font-size: .8em;
    color: #20C20E;
    font-family: 'Fira Code', cursive;

}
#artwork {
    overflow: hidden !important;
}
#artworkimg {
    overflow: hidden !important;
    width: 600px;
    height: 300px;
}
#hacker {
    color: #20C20E; 
    font-family: 'Fira Code', cursive;

}
#hacker-link {
    color: #20C20E;  
        font-family: 'Fira Code', cursive;

}
#terminaldiv_IDSystem {
    text-align: left;

  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.75), black 120%
  );
  margin: 0;
  padding: .7rem;
  color: white;

  
  
  {/* padding-inline-start: 0px; */} 

  &::after {
    content: "";
    background: repeating-linear-gradient(
      0deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 1px,
      transparent 1px,
      transparent 2px
    );
    pointer-events: none;
  }

}
#black {
    color: black; 
}
/* phones */ 
@media screen and (max-width: 800px) {
    .IDSystem{
    }
    #Badge {
        display: flex;
    }
    #Badgeinput {
        display: flex;
        background-color: black;
        color: #20C20E;
    }
    #flex_idsystem { 
        display:block;
        flex-wrap: nowrap;
        width: 300px;
        height: 500px;
        background-color: black;
        border: 10px white;
    }
    #idcube { 
        height: 216px;
        width: 216px;
        align-content: left;
        text-align: center; 
        border: none;
        box-sizing: border-box;
        
    }
    #stats {
        text-align: left; 
    }
    #cube_text {
        max-width: 300px;
        max-height: 150px;
        overflow: hidden !important;    
        font-size: .8em;
        color: #20C20E;
        font-family: 'Fira Code', cursive;
    
    }
    #artworklink {
       {/* font-size: .8em; */} 
    }
    #artwork {
    }
    #artworkimg {
        width: 600px;
        height: 200px;
        margin: 10px;
    }
    #hacker {
        color: #20C20E; 
        font-family: 'Fira Code', cursive;
    
    }
    #hacker-link {
        color: #20C20E;  
            font-family: 'Fira Code', cursive;
    
    }
    #terminaldiv_IDSystem { 

    }
}