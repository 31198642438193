
/* phones */ 
@media screen and (max-width: 800px) {

    body {
        margin: 0;
        font-family: 'Press Start 2P', cursive;
        background-color:#111;
        padding: 0;
        /*  display: flex; idk if this works ---- This is why i couldnt get content to align */ 
        color: white;
        background-image: url("stars.jpeg");
        background-repeat: repeat;
        max-width: 300px; /* just changed !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
        width: 300px;
        margin: 0 auto; 
      }
      p { 
        margin: 0px;
        padding: 0px;
      } 
      #title{
        background-color: black;
      }
      .Minter {
        background-color:black;
      }
      #introduction {
        background-color: #999;
        color: black;
      }
      #rarity { /* V A R I A T I O N S */ 
        border: 10px solid #999;
        background-color: #111;
      }
      #provenance { /* PROVENANCE */ 
        font-size: .6em;
      }
      #roadmap {
        border: 2px solid #999;
      }
      #community { /* COMMUNITY */ 
          font-family: 'Fira Code', cursive;
      
      }
      #disclaimer { /* disclaimer */ 
        border: 5px solid #999;
        background-color: #111;
        font-size: .6em;

      }
      #border {
        border: 5px solid #999;
      }
      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
      }
      
      #link { 
        color:#999;
        
      }
      .hovfont {
        font-family: 'Cinzel Decorative', cursive ;
      }
      #statbutton:hover {
        background-color: #999;
        color:#111;
        border: 6px solid #111;
      }
      #statbutton {
        font-family: 'Press Start 2P', cursive;
        background-color: #111;
        color: #999;
        border: 4px solid #999;
        padding: 8px;
        font-size: 18px;
        margin: 1px 1px;
        cursor: pointer;
        transition-duration: 0.8s;
      }
      #statbutton:hover {
        background-color: #999;
        color:#111;
        border: 6px solid #111;
      }
      #button {
        font-family: 'Press Start 2P', cursive;
        background-color: #111;
        color: #999;
        border: 4px solid #999;
        padding: 18px;
        font-size: 20px;
        margin: 4px 4px;
        cursor: pointer;
        border-radius: 12px;
        transition-duration: 0.8s;
      
      }
      #button:hover {
        background-color: #999;
        color:#111;
      }
      #buttonspecial { 
        font-family: 'Press Start 2P', cursive;
        background-color: #111;
        color: #FFF;
        border: 2px solid #fff;
        padding: 18px;
        font-size: 20px;
        cursor: pointer;
        border-radius: 12px;
        transition-duration: 0.8s;
      }
      #buttonspecial:hover {
        background-color: #FFF;
        color:#111;
      }
      h1 { 
        font-size: 30px;
      }
      
      #walletButton {
        text-align: center;
        float:center;
        line-height: 16px;
        background-color: black;
        color:#999;
        width: 300px;
      }
      #supermint {
      }
      #ladydescription{
        text-align: center;
        content-align: center;
      }
      /*
      #walletButton:focus {
        outline:none;
        border: none;
      }
      
      #walletButton:hover {
        outline:none;
        border :none;
      } */
      
      #mintButton {
        margin-top: 40px;
        background-color: black;
        color:white;
      }
      #nft {
        width: 300px;
        height: 300px;
        text-align: center;
      }
      #mobilenft {
      }
      #nftmain {
        display: flex;
        padding: 0px;
        margin: 0px;
        width: 350px;
        height: 350px;
        text-align: left;
        border: none;
        overflow: hidden;

      }
      #provenance { /* PROVENANCE */ 
        border: 5px solid #999;
        text-align: center;
      }
      #provenancelist {
        text-align: center;
      
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      #provenancelist {
        margin: 0;
        padding: 0;
        
      }
      #provenancelisthead {
        font-size: 1.4em
        
      }
      #masterdirs {  
        font-family: 'Fira Code', cursive;
      
        font-size: 1em;
      }
      #ipfs_desc {
        font-size: 2em;
      }
      #ipfs_desc_small {
        font-size: 2em;
      }
      .hash {
        font-size: 0.5em;
        color: #20C20E;
        font-weight: 800;
        text-decoration: none;
        font-family: 'Press Start 2P', cursive;
      
      }
      .hash:hover {
        font-size: 0.55em;
        color: #20C20E;
        font-weight: 800;
        text-decoration: underline;
      }
      .hash_alt {
        font-size: 0.5em;
        color: #20C20E;
        font-weight: 800;
        text-decoration: none;
        font-family: 'Press Start 2P', cursive;
      }
      
      .hash_alt:hover {
        color: #20C20E;
        font-weight: 800;
        text-decoration: underline;
        font-size: 0.55em;
      
      }
      .tokenid {
        font-family: 'Fira Code', cursive;
      
       color: white;
       text-decoration: none;
      
      }
      .tokenid {
        font-family: 'Fira Code', cursive;
      
       color: white;
       text-decoration: none;
      
      }
      .META {
        color: #20C20E;
        font-size: 0.6em;
        text-decoration: none;
      
      }
      .META:HOVER { 
        text-decoration: underline;
        font-weight: 800;
      
      }
      
      #fira { 
        font-family: 'Fira Code', cursive;
      }
      #provenancelisthead {
        font-family: 'Fira Code', cursive;
        font-size: 2em;
      
      }
      
      #roadmap {
        font-family: 'Fira Code', cursive;
        font-size: 1em;
      
      }
      
      #hacker2 {
        color: #20C20E; 
      
      }

      #manifestoroadmap {
        font-family: 'Fira Code', cursive;
        font-size: 1em;
        width: 320px;
      
      }
      #manifestoheader { 
        font-size: 1.3em;        
      }
      #manifestocontent {
        font-size: .85em;
        width: 270px;
      }
      #linksdiv {
        width: 1000px;
        max-width: 2000px;
      }
      .navlink {
  
        font-family: "Press Start 2P",cursive;
        background-color: #999;
        color: #111;
        border: 4px solid #111;
        padding: 10px;
        font-size: 10px;
        cursor: pointer;
        border-radius: 12px;
        transition-duration: .8s;
        margin-bottom: 20px;
        inherits: none; 
      }
      #provbutton {
      }
      #datanavbar{ 
        text-align: left;
      }
      #minternavbar{ 
        text-align: left;
      }
      #logomystery { 
        width: 250px;
      }
      #buttonmintandredeem{
        width: 200px;
      }
      #tutorial{
        width: 280px;
        border: 4px solid grey;
      }
      #logocubes{
        width: 300px;
        text-align: left;

      }
      #artworkimg{
        width:300px;
      }
      #launchdate{
        font-size: 1.7em;
      }
      /*Welcome to Club Main Terminal section */ 
      #terminaldiv{
        font-size: .94em;
        
      }
      #hacker3{ 
        line-height: 45px;
      }
      #provenancelist{
        font-size: .91em !important;
      }
}



/* id system mobile is in idsystem.css */ 

